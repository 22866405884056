import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Grid,
  Box,
  Container,
  SpaceBetween,
  Button,
  Link,
  Header,
  Badge,
  Modal,
  ColumnLayout,
  PieChart,
  BarChart,
  Alert,
} from "@cloudscape-design/components";
import { useHistory } from "react-router-dom";
import { applyTheme } from "@cloudscape-design/components/theming";
import React, { useEffect, useState, useMemo } from "react";
import "../App.css";
import "../styles.scss";
import { ReactTyped } from "react-typed";

applyTheme({
  theme: {
    tokens: {
      colorBackgroundLayoutMain: {
        dark: "#000716",
      },
      fontFamilyBase:
        "'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif",
      borderRadiusContainer: "0.125rem",
      borderRadiusButton: "0.250rem",
      colorBackgroundContainerContent: "#000716",
    },
    contexts: {
      "top-navigation": {
        tokens: {
          colorBackgroundContainerContent: "#000716",
          colorBackgroundContainerHeader: "#000716",
        },
      },

      header: {
        tokens: {
          colorBackgroundContainerHeader: "#000716",
        },
      },
    },
  },
});

export function Home() {
  const [jetsonModalVisible, setJetsonModalVisible] = useState(false);
  const jetsonVideoURL = "/nano-jetson-yolo.mp4";

  const [guruPharmaAdModalVisible, setGuruPharmaAdModalVisible] =
    useState(false);
  const guruPharmaAdVideoURL = "/pharma-ad-generator.mp4";

  const [guruChatbotModalVisible, setGuruChatbotModalVisible] = useState(false);
  const guruChatbotVideoURL = "/guru-chatbot.mp4";

  const [
    simpleDatabaseArchivingModalVisible,
    setSimpleDatabaseArchivingModalVisible,
  ] = useState(false);
  const simpleDatabaseArchivingVideoURL = "/sdas-demo.mp4";

  const i18nStrings = {
    chartAriaRoleDescription: "Pie chart of technical experience",
    detailPopoverDismissAriaLabel: "Close",
    detailsPercentage: "Percentage",
    detailsValue: "Value",
    filterLabel: "Filter",
    filterPlaceholder: "Select here to filter...",
    filterSelectedAriaLabel: "Selected",
    legendAriaLabel: "Legend",
    segmentAriaRoleDescription: "segment",
  };

  return (
    <SpaceBetween size="xs">
      <Container>
        <div className="full-header">
          <div className="inner-header">
            <Grid
              gridDefinition={[
                { colspan: { default: 12, xs: 8, s: 9 } },
                { colspan: { default: 12, xs: 4, s: 3 } },
              ]}
            >
              <div style={{ paddingRight: "3%" }}>
                <Box fontWeight="normal" variant="h4" className="orange-text">
                  Hi, my name is
                </Box>
                <Box variant="h3">Jin Tan Ruan.</Box>

                <Box variant="h3">
                  I develop{" "}
                  <ReactTyped
                    strings={[
                      "generative AI applications on the cloud",
                      "scalable AI solutions in the cloud",
                      "cloud-native AI applications",
                      "AI-powered content generation tools",
                      "text-to-image AI systems",
                      "natural language processing tools",
                      "AI-powered software automation",
                    ]}
                    typeSpeed={50}
                    loop
                    backSpeed={20}
                    cursorChar=" _"
                    showCursor={true}
                  />
                </Box>

                <Box
                  variant="p"
                  color="text-body-secondary"
                  margin={{ top: "xxs", bottom: "s" }}
                >
                  My name is Jin, and I'm a software engineer based in New York.
                  I specialize in designing and building intelligent, scalable,
                  and resilient cloud applications. Currently, I work as a
                  prototyping developer on the AWS Industries Prototyping and
                  Customer Engineering team at{" "}
                  <Link variant="secondary" href="https://aws.amazon.com/">
                    Amazon Web Services
                  </Link>
                  , with a focus on natural language processing and generative
                  AI.
                </Box>

                <Box
                  variant="p"
                  color="text-body-secondary"
                  margin={{ top: "xxs", bottom: "s" }}
                >
                  I am a 10x Certified AWS Machine Learning & Data Analytics
                  expert and a certified Automation Anywhere RPA Bot Developer,
                  dedicated to creating cutting-edge web and mobile
                  applications. With both a bachelor's and a master's degree in
                  Computer Science from Syracuse University, I have a robust
                  foundation in process-oriented software development.
                </Box>
                <Box
                  variant="p"
                  color="text-body-secondary"
                  margin={{ top: "xxs", bottom: "s" }}
                >
                  My extensive experience spans iOS development, generative AI,
                  large language models, and YoloV3 Darknet53 image recognition
                  technology. Throughout my career, I've had the privilege of
                  contributing to a student-led newspaper, a startup, a
                  freelancer platform, and a global consulting leader.
                </Box>

                <Box
                  variant="p"
                  color="text-body-secondary"
                  margin={{ top: "xxs", bottom: "s" }}
                >
                  Currently, I am focused on pioneering innovative and
                  intelligent applications in the healthcare, life sciences, and
                  automotive industries, powered by large language models.
                  Recently, I launched three groundbreaking generative AI
                  applications that serve as practical guides to leveraging AWS
                  services for crafting sophisticated Large Language Model (LLM)
                  Generative AI. These applications are designed to create
                  responsive question-and-answer bots and enable localized
                  content generation, showcasing the transformative potential of
                  AI in real-world scenarios.
                </Box>

                <SpaceBetween size="xs">
                  <div>
                    Retrieval Augmented Generation :{" "}
                    <Link
                      variant="secondary"
                      href="https://github.com/aws-samples/pace-genai-demos/tree/main/Kendra-Foundational-LLM-Chatbot"
                    >
                      Amazon Kendra Chatbot
                    </Link>
                    {" | "}
                    <Link
                      variant="secondary"
                      href="https://github.com/aws-samples/pace-genai-demos/tree/main/Embeddings-Foundational-LLM-ChatBot"
                    >
                      Custom Embeddings Chatbot
                    </Link>
                  </div>
                  <div>
                    Pharma Image Generation :{" "}
                    <Link
                      variant="secondary"
                      href="https://github.com/aws-samples/pace-genai-demos/tree/main/Pharma-Ad-Generator"
                    >
                      Pharma Ad Generator
                    </Link>
                  </div>
                  <div>
                    Data Archiving Solution :{" "}
                    <Link
                      variant="secondary"
                      href="https://github.com/awslabs/simple-database-archival-solution"
                    >
                      Simple Database Archival Solution
                    </Link>
                  </div>
                  <div>
                    Blogs / Publications :{" "}
                    <Link
                      variant="secondary"
                      href="https://aws.amazon.com/blogs/machine-learning/harnessing-the-power-of-enterprise-data-with-generative-ai-insights-from-amazon-kendra-langchain-and-large-language-models/"
                    >
                      Harnessing the power of enterprise data with generative AI
                    </Link>
                    {" | "}
                    <Link
                      variant="secondary"
                      href="https://aws.amazon.com/blogs/opensource/announcing-the-simple-database-archival-solution/"
                    >
                      Announcing Simple Database Archival Solution
                    </Link>
                    {" | "}
                    <Link
                      variant="secondary"
                      href="https://aws.amazon.com/blogs/machine-learning/best-prompting-practices-for-using-the-llama-2-chat-llm-through-amazon-sagemaker-jumpstart/"
                    >
                      Best prompting practices for using the Llama2 Chat
                    </Link>
                    {" | "}
                    <Link
                      variant="secondary"
                      href="https://community.aws/content/2dgknRpCYT6OYiPK6GQHfSFW0KS/"
                    >
                      Things to Consider When Building Your First Generative AI
                      Application
                    </Link>
                  </div>
                  <div>
                    Papers :{" "}
                    <Link
                      variant="secondary"
                      href="https://aws.amazon.com/blogs/machine-learning/harnessing-the-power-of-enterprise-data-with-generative-ai-insights-from-amazon-kendra-langchain-and-large-language-models/"
                    >
                      2048 GAME AI
                    </Link>
                    {" | "}
                    <Link
                      variant="secondary"
                      href="https://aws.amazon.com/blogs/opensource/announcing-the-simple-database-archival-solution/"
                    >
                      YOLOv3: An Incremental Improvement – Core ML IOS Mobile
                    </Link>
                  </div>
                </SpaceBetween>

                <SpaceBetween size="s">
                  <Box
                    variant="h5"
                    fontWeight="normal"
                    color="text-body-secondary"
                    margin={{ top: "xxl", bottom: "s" }}
                  >
                    Latest update as of June 02, 2024, 14:32 (UTC+3:30)
                  </Box>
                </SpaceBetween>
              </div>

              <div style={{ paddingLeft: "3%" }}>
                <Box margin={{ top: "l" }}>
                  <img
                    src="/profile.svg"
                    alt="profile"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              </div>
            </Grid>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">
                  Multilingual Abilities I've Developed
                </Header>
              </Box>
              <SpaceBetween size="xxl">
                <div>
                  <SpaceBetween size="m">
                    <Header variant="h3">
                      <Box variant="h3" margin={{ top: "xxs", bottom: "s" }}>
                        Fluent Language Skills
                      </Box>

                      <Box
                        variant="p"
                        color="text-body-secondary"
                        margin={{ top: "xxs", bottom: "s" }}
                      >
                        In today's globalized world, the ability to communicate
                        across cultures is invaluable. I am fluent in English,
                        Spanish, and Chinese Cantonese, enabling me to engage
                        effectively with diverse audiences and contribute to
                        multicultural environments. These language skills not
                        only facilitate professional interactions but also
                        enrich personal experiences, allowing for deeper
                        connections with people around the world.
                      </Box>
                    </Header>

                    <ColumnLayout columns={3}>
                      <div className="centered-content">
                        <img style={{ width: "60%" }} src="/english.svg" />
                        <Box textAlign="center" variant="h4">
                          English
                        </Box>
                      </div>
                      <div className="centered-content">
                        {" "}
                        <img style={{ width: "60%" }} src="/spanish.svg" />
                        <Box textAlign="center" variant="h4">
                          Spanish
                        </Box>
                      </div>
                      <div className="centered-content">
                        {" "}
                        <img style={{ width: "60%" }} src="/chinese.svg" />
                        <Box textAlign="center" variant="h4">
                          Chinese Cantonese
                        </Box>
                      </div>
                    </ColumnLayout>
                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">Certifications I've Achieved</Header>
              </Box>
              <SpaceBetween size="xxl">
                <div>
                  <SpaceBetween size="m">
                    <Header variant="h3">
                      <Box variant="h3" margin={{ top: "xxs", bottom: "s" }}>
                        Technical Certifications
                      </Box>
                      <Box
                        variant="p"
                        color="text-body-secondary"
                        margin={{ top: "xxs", bottom: "s" }}
                      >
                        As a technology professional committed to continuous
                        growth and development, I have pursued and achieved
                        various certifications that showcase my expertise across
                        different areas. Below are the certifications I've
                        earned, structured to reflect foundational knowledge,
                        specialized skills, and advanced proficiencies. Each
                        certification is a testament to my dedication to
                        maintaining the highest standards of knowledge and
                        practice in the ever-evolving tech landscape.
                      </Box>
                    </Header>
                    <ColumnLayout columns={2}>
                      <div className="centered-content">
                        <div>
                          <Box
                            fontWeight="bold"
                            variant="h3"
                            margin={{ bottom: "xl" }}
                          >
                            <Badge color="green">EXPERT</Badge>
                          </Box>
                        </div>
                        <div>
                          <img
                            style={{ width: "25%" }}
                            src="/automation-analyst.svg"
                            alt="Automation 360 Business Analyst"
                          />
                          <img
                            style={{ width: "25%" }}
                            src="/automation-citizen-developer.svg"
                            alt="Automation 360 Citizen Developer"
                          />
                          <img
                            style={{ width: "25%" }}
                            src="/automation-developer.svg"
                            alt="Automation 360 Bot Developer"
                          />
                        </div>
                      </div>

                      <div className="centered-content">
                        <div>
                          <Box
                            fontWeight="bold"
                            variant="h3"
                            margin={{ bottom: "xl" }}
                          >
                            <Badge color="green">PROFESSIONAL</Badge>
                          </Box>
                        </div>
                        <div>
                          <a
                            href="https://www.credly.com/badges/8696b9c0-278e-4796-8c63-ee803a6e08d2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-architect-professional.svg"
                              alt="AWS Certified Solutions Architect Professional"
                            />
                          </a>
                          <a
                            href="https://www.credly.com/badges/df2863e7-1a98-4c5d-8fcc-ec00c4dc7cf0"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-devops-professional.svg"
                              alt="AWS Certified DevOps Engineer Professional"
                            />
                          </a>
                        </div>
                      </div>
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                      <div className="centered-content">
                        <div>
                          <Box
                            fontWeight="bold"
                            variant="h3"
                            margin={{ bottom: "xl" }}
                          >
                            <Badge color="green">ASSOCIATE</Badge>
                          </Box>
                        </div>
                        <div>
                          <a
                            href="https://www.credly.com/badges/d7e2700b-0a5a-4d31-a18f-9b92ffd81380"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-architect-associate.svg"
                              alt="AWS Certified Solutions Architect Associate"
                            />
                          </a>
                          <a
                            href="https://www.credly.com/badges/df4dd7c7-0f9c-43e7-979f-88e06ab0d84f"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-developer-associate.svg"
                              alt="AWS Certified Developer Associate"
                            />
                          </a>

                          <a
                            href="https://www.credly.com/badges/dbb8c08d-1673-4911-b540-2acac0664ef5"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-sysops-associate.svg"
                              alt="AWS Certified SysOps Administrator Associate"
                            />
                          </a>
                        </div>
                      </div>

                      <div className="centered-content">
                        <div>
                          <Box
                            fontWeight="bold"
                            variant="h3"
                            margin={{ bottom: "xl" }}
                          >
                            <Badge color="green">SPECIALTY</Badge>
                          </Box>
                        </div>

                        <div>
                          <a
                            href="https://www.credly.com/badges/644edc11-ea74-42a4-ac19-f15f5687c90d"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-machine-learning-specialty.svg"
                              alt="AWS Certified Machine Learning Specialty"
                            />
                          </a>
                          <a
                            href="https://www.credly.com/badges/2c5a6d17-e580-4f74-bc8e-270ef12864da"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-data-specialty.svg"
                              alt="AWS Certified Big Data Specialty"
                            />
                          </a>
                          <a
                            href="https://www.credly.com/badges/2c5a6d17-e580-4f74-bc8e-270ef12864da"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              style={{ width: "25%" }}
                              src="/aws-analytics-specialty.svg"
                              alt="AWS Certified Data Analytics Specialty"
                            />
                          </a>
                        </div>
                      </div>
                    </ColumnLayout>
                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">Programming Skills I've Mastered</Header>
              </Box>

              <SpaceBetween size="xxl">
                <div>
                  <SpaceBetween size="m">
                    <Header variant="h3">
                      <Box variant="h3" margin={{ top: "xxs", bottom: "s" }}>
                        Technical Proficiency Across Multiple Languages
                      </Box>

                      <Box
                        variant="p"
                        color="text-body-secondary"
                        margin={{ top: "xxs", bottom: "s" }}
                      >
                        I have developed a profound mastery over a broad
                        spectrum of programming languages, equipping me to
                        design sophisticated web platforms, develop resilient
                        software architectures, and formulate advanced machine
                        learning models. My expertise enables the innovation of
                        cutting-edge solutions that propel technological
                        advancements and industry progress. Through crafting
                        robust web interfaces and devising intricate algorithms
                        for machine learning, I consistently leverage my
                        programming prowess to pioneer innovative applications
                        and push the frontiers of digital technology.
                      </Box>
                    </Header>
                  </SpaceBetween>
                </div>

                <div style={{ paddingBottom: "2%" }}>
                  <BarChart
                    series={[
                      {
                        title: "Python",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 90 }],
                      },
                      {
                        title: "SQL",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 87 }],
                      },
                      {
                        title: "Swift",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 83 }],
                      },
                      {
                        title: "SwiftUI",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 82 }],
                      },
                      {
                        title: "JavaScript",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 82 }],
                      },
                      {
                        title: "TypeScript",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 79 }],
                      },
                      {
                        title: "Objective C",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 78 }],
                      },
                      {
                        title: "Java",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 75 }],
                      },
                      {
                        title: "HTML",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 72 }],
                      },
                      {
                        title: "Solidity",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 68 }],
                      },
                      {
                        title: "Kotlin",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 64 }],
                      },
                      {
                        title: "Haskell",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 63 }],
                      },

                      {
                        title: "C++",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 63 }],
                      },

                      {
                        title: "Scala",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 32 }],
                      },
                      {
                        title: "Go",
                        type: "bar",
                        valueFormatter: (e) => `${e}%`,
                        data: [{ x: "Language", y: 26 }],
                      },
                    ]}
                    xDomain={["Language"]}
                    yDomain={[0, 100]}
                    i18nStrings={{
                      yTickFormatter: (e) => `${e}%`,
                    }}
                    ariaLabel="Stacked, horizontal bar chart"
                    height={500}
                    horizontalBars
                    hideFilter
                  />
                </div>

                <div>
                  <SpaceBetween>
                    <ColumnLayout columns={2}>
                      <div className="centered-content">
                        <div>
                          <PieChart
                            data={[
                              {
                                title: "Healthcare and Life Science",
                                value: 65,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Technology",
                                value: 15,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Automobile",
                                value: 12,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Finance",
                                value: 3,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Other",
                                value: 5,
                                lastUpdate: "June 02, 2024",
                              },
                            ]}
                            detailPopoverContent={(datum, sum) => [
                              {
                                key: "Industry Experience",
                                value: `${((datum.value / sum) * 100).toFixed(
                                  0
                                )}%`,
                              },
                              {
                                key: "Last updated",
                                value: datum.lastUpdate,
                              },
                            ]}
                            segmentDescription={(datum, sum) =>
                              `${datum.title}: ${(
                                (datum.value / sum) *
                                100
                              ).toFixed(0)}%`
                            }
                            ariaDescription="Pie chart showing the percentage of experience across various industries."
                            ariaLabel="Industry exposure distribution"
                            size="large"
                            hideFilter
                          />
                        </div>
                      </div>
                      <div className="centered-content">
                        <div>
                          <PieChart
                            data={[
                              {
                                title: "Generative AI",
                                value: 70,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Machine Learning",
                                value: 20,
                                lastUpdate: "June 02, 2024",
                              },

                              {
                                title: "Blockchain",
                                value: 5,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "IoT",
                                value: 3,
                                lastUpdate: "June 02, 2024",
                              },
                              {
                                title: "Other",
                                value: 2,
                                lastUpdate: "June 02, 2024",
                              },
                            ]}
                            detailPopoverContent={(datum, sum) => [
                              {
                                key: "Percentage of Experience",
                                value: `${((datum.value / sum) * 100).toFixed(
                                  0
                                )}%`,
                              },
                              {
                                key: "Last updated",
                                value: datum.lastUpdate,
                              },
                            ]}
                            segmentDescription={(datum, sum) =>
                              `${datum.title}: ${(
                                (datum.value / sum) *
                                100
                              ).toFixed(0)}%`
                            }
                            ariaDescription="Pie chart showing the percentage of technical experience in different technology domains."
                            ariaLabel="Technical experience distribution"
                            size="large"
                            hideFilter
                          />
                        </div>
                      </div>
                    </ColumnLayout>
                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">Where I’ve Worked</Header>
              </Box>
            </div>

            <div>
              <SpaceBetween size="m">
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/aws.svg"
                        alt="Amazon Web Services"
                        style={{
                          maxHeight: "34px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">
                      Prototyping Developer{" "}
                      <Badge variant="filled" color="red">
                        Current Position
                      </Badge>
                    </Box>
                    <Box fontWeight="normal" variant="h4">
                      January 2022 - Present
                    </Box>
                  </Header>

                  <Box margin={{ top: "l", bottom: "l" }}>
                    <Alert statusIconAriaLabel="Success" type="success">
                      As a member of the AWS Industries engineering and
                      prototyping team, I work with business leaders across all
                      industries. Our primary objective is to expedite the
                      adoption of the AWS platform by crafting intricate and
                      sophisticated prototypes. These prototypes are pivotal in
                      accelerating business decision-making processes and
                      showcasing the practicality of emerging technologies. Our
                      efforts significantly reduce the timeline from preliminary
                      discussions to the deployment of technical solutions, with
                      a particular focus on Generative AI applications and
                      challenges related to Large Language Models (LLM).
                    </Alert>
                  </Box>

                  <Box variant="p">
                    As a Prototyping Developer focused on Machine Learning and
                    Generative AI, I lead the development of groundbreaking
                    prototypes that inform critical business decisions. Working
                    closely with clients, I harness advanced AWS technologies to
                    craft inventive solutions that redefine possibilities. I
                    collaborate with diverse stakeholders to design customized
                    solutions, driving AWS adoption and addressing precise
                    customer requirements.
                  </Box>
                  <Box variant="p">
                    <ul>
                      <li>
                        Lead the conception and development of machine learning
                        prototypes on the AWS platform, showcasing the potential
                        of AI in driving business innovation.
                      </li>
                      <li>
                        Stay abreast of the latest advancements in machine
                        learning and generative AI, continually refining
                        prototype development processes to incorporate
                        cutting-edge techniques.
                      </li>
                      <li>
                        Collaborate closely with customers to understand their
                        unique needs, translating requirements into impactful
                        prototypes that address specific use cases.
                      </li>
                      <li>
                        Employ a full-stack development approach, proficiently
                        utilizing multiple languages and frameworks to bring
                        prototypes to life in emerging technology areas such as
                        spatial computing AI/ML, compute at the edge, and
                        robotics.
                      </li>
                    </ul>
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/deloitte.svg"
                        alt="Deloitte"
                        style={{
                          maxHeight: "20px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">Software Developer</Box>
                    <Box fontWeight="normal" variant="h4">
                      May 2021 - January 2022
                    </Box>
                  </Header>
                  <Box variant="p">
                    Directed the design and development of cutting-edge
                    enterprise-level microservice applications in the cloud for
                    a global leader in renal disease treatment, impacting over
                    190,000 patients across 2,400+ facilities nationwide.
                    Engineered scalable system APIs and background workers to
                    handle 450 million sensitive patient messages and requests.
                  </Box>
                  <Box variant="p">
                    <ul>
                      <li>
                        Led the design and development of high-impact
                        enterprise-level microservice applications utilizing
                        AWS, Oracle DB, and JavaScript for a worldwide leader in
                        renal disease treatment.
                      </li>
                      <li>
                        Engineered scalable system APIs and background workers
                        to efficiently manage 450 million sensitive and
                        confidential patient messages and requests nationwide.
                      </li>
                      <li>
                        Developed a robust hybrid infrastructure using
                        Infrastructure as Code (IaC) with CloudFormation and
                        Cloud Development Kit, achieving $8.3 billion in annual
                        CAPEX savings.
                      </li>
                    </ul>
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/logo.svg"
                        alt="Snappycode"
                        style={{
                          maxHeight: "45px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">Founder | iOS Application Developer</Box>
                    <Box fontWeight="normal" variant="h4">
                      February 2020 - May 2021
                    </Box>
                  </Header>
                  <Box variant="p">
                    Innovatively collaborated with the UX team to transform
                    visionary concepts into delivered solutions through superior
                    software design, coding, and processes. Proactively
                    discovered, evaluated, and implemented cutting-edge
                    technologies to enhance development efficiency and product
                    excellence.
                  </Box>

                  <Box variant="p">
                    <ul>
                      <li>
                        Participated in code reviews, wrote automated tests, and
                        helped define the technical roadmap.
                      </li>
                      <li>
                        Worked closely with the UX team to execute ideas from
                        concept to delivery through exceptional software design,
                        coding, and processes.
                      </li>
                      <li>
                        Continuously discovered, evaluated, and implemented new
                        technologies to maximize development efficiency.
                      </li>
                    </ul>
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/itemize.svg"
                        alt="Itemize"
                        style={{
                          maxHeight: "28px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">Co-Founder | Software Engineer</Box>
                    <Box fontWeight="normal" variant="h4">
                      January 2020 - May 2021
                    </Box>
                  </Header>
                  <Box variant="p">
                    <Link
                      variant="secondary"
                      href="https://dailyorange.com/2021/03/ischool-itemize-app-stolen-belongings/"
                    >
                      Itemize
                    </Link>{" "}
                    is a platform that serves as your verification for the
                    electronic products you work hard to purchase. Played a
                    crucial role in identifying potential issues and developing
                    comprehensive documentation to support ongoing development
                    activities. Enhanced product quality through detailed
                    analysis, design, development, and performance optimization
                    of web applications, alongside conducting code reviews and
                    implementing effective automation testing.
                  </Box>
                  <Box variant="p">
                    <ul>
                      <li>
                        Identified potential issues early and developed
                        comprehensive documentation to support seamless ongoing
                        development activities.
                      </li>
                      <li>
                        Enhanced product quality by participating in the
                        analysis, design, development, and performance
                        optimization of web applications.
                      </li>
                      <li>
                        Conducted thorough code reviews to ensure code quality
                        and consistency.
                      </li>
                      <li>
                        Implemented effective automation testing to streamline
                        the development process and improve reliability.
                      </li>
                    </ul>
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/syracuse.svg"
                        alt="Syracuse University Engineering School"
                        style={{
                          maxHeight: "42px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">
                      Graduate Teaching Assistant | Structured Programming and
                      Formal Methods
                    </Box>
                    <Box fontWeight="normal" variant="h4">
                      January 2021 - May 2021
                    </Box>
                  </Header>
                  <Box variant="p">
                    Supported the professor by grading assignments and leading
                    recitations, significantly enhancing the learning experience
                    for students. Developed and delivered dynamic lectures on
                    Finite State Automata, Turing Machines, and the Halting
                    Problem, while advising and assisting students with course
                    material (Structured Programming and Formal Methods) during
                    and outside office hours.
                  </Box>
                  <Box variant="p">
                    <ul>
                      <li>
                        Graded assignments and led recitations to support the
                        professor and improve the learning experience for
                        students.
                      </li>
                      <li>
                        Developed and delivered engaging lectures on complex
                        topics such as Finite State Automata, Turing Machines,
                        and the Halting Problem.
                      </li>
                      <li>
                        Provided advisory and assistance to students with course
                        material during and outside office hours, ensuring
                        comprehensive understanding.
                      </li>
                    </ul>
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/syracuse.svg"
                        alt="Syracuse University IT Department"
                        style={{
                          maxHeight: "42px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">Junior Technology Consultant</Box>
                    <Box fontWeight="normal" variant="h4">
                      January 2020 - August 2020
                    </Box>
                  </Header>
                  <Box variant="p">
                    Delivered comprehensive IT services to ECS students,
                    faculty, and staff, ensuring timely support and optimal
                    functionality. Expertly utilized diverse frameworks,
                    platforms, CMSs, and programming languages to enhance
                    teaching, research, and administrative operations.
                  </Box>
                  <Box variant="p">
                    <ul>
                      <li>
                        Provided comprehensive IT services to ECS students,
                        faculty, and staff, ensuring timely and effective
                        support.
                      </li>
                      <li>
                        Utilized diverse frameworks, platforms, CMSs, and
                        programming languages to enhance the efficiency and
                        effectiveness of teaching, research, and administrative
                        operations.
                      </li>
                      <li>
                        Ensured optimal functionality and resolved IT issues
                        promptly to maintain smooth operations across the
                        department.
                      </li>
                    </ul>
                  </Box>
                </div>
              </SpaceBetween>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">Some Things I’ve Built</Header>
              </Box>
            </div>

            <SpaceBetween size="xxl">
              <Container
                media={{
                  content: (
                    <Link href="#">
                      <img src="/guru-ad.png" alt="Guru Pharma Ad" />
                    </Link>
                  ),
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <SpaceBetween direction="vertical" size="xxs">
                    <Box variant="small">
                      HCLS Generative AI - Amazon Bedrock
                    </Box>

                    <Box variant="h2">
                      Foundation Models Pharma Ad Generator
                    </Box>
                    <Box>
                      <SpaceBetween direction="horizontal" size="s">
                        <Badge color="green">Generative AI Applications</Badge>
                        <Badge color="green">Foundational Models</Badge>
                        <Badge color="green">Regulatory Compliance AI</Badge>
                        <Badge color="green">Compliant Ad Generation</Badge>
                        <Badge color="green">LLM</Badge>
                        <Badge color="green">Amazon Bedrock</Badge>
                        <Badge color="green">AI Ad Generation Tools</Badge>
                      </SpaceBetween>
                    </Box>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    A specialized application tailored specifically for the
                    pharmaceutical industry, leveraging the generative
                    capabilities of foundational models to create compelling and
                    compliant pharmaceutical advertisements. It ensures that all
                    content adheres strictly to industry standards and
                    regulations. In addition to accelerating the ad creation
                    process, this tool significantly streamlines the Medical
                    Legal Review process. During this critical phase, medical,
                    legal, and regulatory teams meticulously evaluate
                    promotional materials to guarantee their accuracy,
                    scientific validity, and regulatory compliance. By
                    integrating these advanced generative techniques, the
                    application not only enhances efficiency but also upholds
                    the highest standards of integrity and compliance in
                    pharmaceutical advertising.
                    <SpaceBetween direction="horizontal" size="m">
                      <Button
                        ariaLabel="Foundation Models Pharma Ad Generator (opens new tab)"
                        href="https://github.com/aws-samples/pace-genai-demos/tree/main/Pharma-Ad-Generator"
                        iconAlign="left"
                        variant="primary"
                        iconUrl="/github.svg"
                        target="_blank"
                      >
                        Source Code
                      </Button>
                      <Button
                        ariaLabel="Foundation Models Pharma Ad Generator Video (opens new tab)"
                        onClick={() => setGuruPharmaAdModalVisible(true)}
                        iconAlign="left"
                        iconUrl="/youtube.svg"
                      >
                        Video Demo
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>

              <Container
                media={{
                  content: (
                    <Link href="#">
                      <img
                        src="/guru-embeddings.png"
                        alt="Guru Embeddings Chatbot"
                      />
                    </Link>
                  ),
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <SpaceBetween direction="vertical" size="xxs">
                    <Box variant="small">
                      Enterprise Generative AI - Amazon Bedrock
                    </Box>
                    <Box variant="h2">
                      Embeddings Model With Foundational LLM
                    </Box>
                    <Box>
                      <SpaceBetween direction="horizontal" size="s">
                        <Badge color="green">Embeddings Models</Badge>
                        <Badge color="green">Knowledge Base</Badge>
                        <Badge color="green">Semantic Search</Badge>
                        <Badge color="green">Generative AI Applications</Badge>
                        <Badge color="green">
                          Retrieval Augmented Generation
                        </Badge>
                        <Badge color="green">Chatbot</Badge>
                        <Badge color="green">Amazon Bedrock</Badge>
                      </SpaceBetween>
                    </Box>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    Merges the power of embeddings—a technique to capture
                    semantic meanings of words and phrases—with the vast
                    knowledge base of LLMs. This synergy enables more accurate
                    topic modeling, content recommendation, and semantic search
                    capabilities. Unlike conventional models with a set
                    knowledge endpoint, RAG’s external database can undergo
                    regular updates, granting the model access to up-to-date
                    information. The retrieval function can be fine-tuned for
                    distinct tasks. For example, a medical diagnostic task can
                    source data from medical journals, ensuring the model
                    garners expert and pertinent insights.
                    <SpaceBetween direction="horizontal" size="m">
                      <Button
                        ariaLabel="Embeddings Model Source Code (opens new tab)"
                        href="https://github.com/aws-samples/pace-genai-demos/tree/main/Embeddings-Foundational-LLM-ChatBot"
                        iconAlign="left"
                        variant="primary"
                        iconUrl="/github.svg"
                        target="_blank"
                      >
                        Source Code
                      </Button>
                      <Button
                        ariaLabel="Embeddings Model Video (opens new tab)"
                        onClick={() => setGuruChatbotModalVisible(true)}
                        iconAlign="left"
                        iconUrl="/youtube.svg"
                      >
                        Video Demo
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>

              <Container
                media={{
                  content: (
                    <Link href="#">
                      <img
                        src="/jetson-nano-edge.png"
                        alt="Jetson Nano Edge Yolo"
                      />
                    </Link>
                  ),
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <SpaceBetween direction="vertical" size="xxs">
                    <Box variant="small">
                      Edge and Cloud Computing - Machine Learning
                    </Box>
                    <Box variant="h2">
                      Computer Vision - Jetson Nano Edge Yolo
                    </Box>
                    <Box>
                      <SpaceBetween direction="horizontal" size="s">
                        <Badge color="green">YoloV8</Badge>
                        <Badge color="green">Edge Computing</Badge>
                        <Badge color="green">Computer Vision</Badge>
                        <Badge color="green">IoT</Badge>
                        <Badge color="green">Image Recognition</Badge>
                        <Badge color="green">Image Classification</Badge>
                        <Badge color="green">Nvidia IoT Device</Badge>
                      </SpaceBetween>
                    </Box>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    Streamline and scale the deployment process of machine
                    learning models and data processing at the edge with our
                    cutting-edge solutions. Enhance efficiency and performance
                    by implementing advanced, state-of-the-art technologies that
                    ensure seamless integration and robust functionality in edge
                    environments. Our approach is designed to handle the
                    complexities of real-time data processing and analysis,
                    making it ideal for applications requiring immediate
                    insights and actions.
                    <Button
                      ariaLabel="Jetson Nano Edge Yolo (opens new tab)"
                      onClick={() => setJetsonModalVisible(true)}
                      iconAlign="left"
                      iconUrl="/youtube.svg"
                    >
                      Video Demo
                    </Button>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>

              <Container
                media={{
                  content: (
                    <Link href="#">
                      <img
                        src="/sdas.png"
                        alt="Simple Database Archiving Solution"
                      />
                    </Link>
                  ),
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  <SpaceBetween direction="vertical" size="xxs">
                    <Box variant="small">Data Archiving - Database</Box>
                    <Box variant="h2">Simple Database Archiving Solution</Box>
                    <Box>
                      <SpaceBetween direction="horizontal" size="s">
                        <Badge color="green">Data Archiving</Badge>
                        <Badge color="green">Migration</Badge>
                        <Badge color="green">Data Validation</Badge>
                        <Badge color="green">Oracle</Badge>
                        <Badge color="green">PostgreSQL</Badge>
                        <Badge color="green">MySQL</Badge>
                        <Badge color="green">Microsoft SQL</Badge>
                        <Badge color="green">Legacy Database</Badge>
                        <Badge color="green">Access Control</Badge>
                        <Badge color="green">Data Compliance</Badge>
                      </SpaceBetween>
                    </Box>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    An open source solution which you can deploy in your AWS
                    account to archive data to AWS. SDAS provides organizations
                    with an efficient, easy and cost-effective solution for
                    archiving Oracle, Microsoft SQL, PostgreSQL, and MySQL
                    databases. SDAS will connect to your database which can be
                    on premises or in the cloud, map the schema, perform
                    validation, and finally transfer data to AWS for storage in
                    Amazon S3. This is accomplished by primarily leveraging AWS
                    Step Functions and AWS Glue.
                    <SpaceBetween direction="horizontal" size="m">
                      <Button
                        ariaLabel="Simple Database Archiving Source Code (opens new tab)"
                        href="https://github.com/awslabs/simple-database-archival-solution?tab=readme-ov-file"
                        iconAlign="left"
                        variant="primary"
                        iconUrl="/github.svg"
                        target="_blank"
                      >
                        Source Code
                      </Button>
                      <Button
                        ariaLabel="Simple Database Archiving Video (opens new tab)"
                        onClick={() =>
                          setSimpleDatabaseArchivingModalVisible(true)
                        }
                        iconAlign="left"
                        iconUrl="/youtube.svg"
                      >
                        Video Demo
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </div>
        </div>
      </Container>

      <Container>
        <div className="full-header">
          <div className="inner-header">
            <div>
              <Box margin={{ bottom: "xxl" }}>
                <Header variant="h1">Where I've Studied</Header>
              </Box>
            </div>

            <div>
              <SpaceBetween size="m">
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/syracuse-school.svg"
                        alt="Syracuse University Engineering and Computer Science"
                        style={{
                          maxHeight: "38px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">
                      Master's Degree, Applied Data Science
                    </Box>
                    <Box fontWeight="normal" variant="h4">
                      January 2020 - May 2021
                    </Box>
                  </Header>

                  <Box variant="p" margin={{ top: "xs" }}>
                    Recipient of the Syracuse University Graduate Award.
                    Accepted into the Concord Dawn U.S. Air Force Cybersecurity
                    Program. Grade point average 3.8 out of 4.0.
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/syracuse-school.svg"
                        alt="Syracuse University Engineering and Computer Science"
                        style={{
                          maxHeight: "38px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">Bachelor's Degree, Computer Science</Box>
                    <Box fontWeight="normal" variant="h4">
                      August 2016 - December 2019
                    </Box>
                  </Header>
                  <Box variant="p" margin={{ top: "xs" }}>
                    Bachelor's Degree in Computer Science in the School of
                    Enginerring and Computer Science. The program is led by Dr.
                    Erich Devendorf from the Air Force Research Lab. Training
                    occurs through a cyber-physical mission set called Concord
                    Dawn, which comprises a series of escalating scenarios
                    demanding technical expertise, adaptability, and strong
                    teamwork to achieve mission goals. Participants supported an
                    air strike on a target by gathering and interpreting
                    intelligence, developing a plan to achieve mission
                    objectives, and executing it as part of a time-phased
                    mission. Dean list 2016 - 2020. Grade point average 3.7 out
                    of 4.0.
                  </Box>
                </div>
                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/john-dewey.svg"
                        alt="John Dewey High School"
                        style={{
                          maxHeight: "45px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">High School Diploma, CSI</Box>
                    <Box fontWeight="normal" variant="h4">
                      May 2014 - July 2016
                    </Box>
                  </Header>
                  <Box variant="p" margin={{ top: "xs" }}>
                    Award of Excellence 2014, Certificate of Recognition for Top
                    10 in the Class 2016, and Certificate of Achievement and
                    Excellence in Advanced Placement Spanish 2016. Grade point
                    average 3.7 out of 4.0.
                  </Box>
                </div>

                <div>
                  <SpaceBetween size="m">
                    <Header>
                      <img
                        src="/nueva-inglaterra.svg"
                        alt="Colegio Nueva Inglaterra"
                        style={{
                          maxHeight: "50px",
                          margin: "0 auto",
                        }}
                      />
                    </Header>
                  </SpaceBetween>
                  <Header variant="h3">
                    <Box variant="h3">
                      High School Diploma, Cambridge International Education
                    </Box>
                    <Box fontWeight="normal" variant="h4">
                      February 2013 - April 2014
                    </Box>
                  </Header>
                  <Box variant="p" margin={{ top: "xs" }}>
                    Cambridge qualifications prepare and equip learners with the
                    skills they need to thrive at university and beyond. The
                    world’s best higher education institutions recognize our
                    qualifications and value the critical thinking skills,
                    independent research abilities, and deep subject knowledge
                    that Cambridge learners bring, helping them succeed at
                    university.
                  </Box>
                </div>
              </SpaceBetween>
            </div>
          </div>
        </div>
      </Container>

      {jetsonModalVisible && (
        <Modal
          onDismiss={() => setJetsonModalVisible(false)}
          visible={jetsonModalVisible}
          size="max"
          key={"jetsonModal"}
        >
          {jetsonVideoURL && (
            <video
              className="video-player"
              controls
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            >
              <source src={jetsonVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal>
      )}
      {guruPharmaAdModalVisible && (
        <Modal
          onDismiss={() => setGuruPharmaAdModalVisible(false)}
          visible={guruPharmaAdModalVisible}
          size="max"
          key={"guruPharmaModal"}
        >
          {guruPharmaAdVideoURL && (
            <video
              className="video-player"
              controls
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            >
              <source src={guruPharmaAdVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal>
      )}

      {guruChatbotModalVisible && (
        <Modal
          onDismiss={() => setGuruChatbotModalVisible(false)}
          visible={guruChatbotModalVisible}
          size="max"
          key={"guruChatbotModal"}
        >
          {guruChatbotVideoURL && (
            <video
              className="video-player"
              controls
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            >
              <source src={guruChatbotVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal>
      )}

      {simpleDatabaseArchivingModalVisible && (
        <Modal
          onDismiss={() => setSimpleDatabaseArchivingModalVisible(false)}
          visible={simpleDatabaseArchivingModalVisible}
          size="max"
          key={"simpleDatabaseArchivingModal"}
        >
          {simpleDatabaseArchivingVideoURL && (
            <video
              className="video-player"
              controls
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            >
              <source src={simpleDatabaseArchivingVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </Modal>
      )}
    </SpaceBetween>
  );
}
